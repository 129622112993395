import React from 'react';
import {
	BrowserRouter as Router,
	Redirect,
	Switch,
	Route,
} from 'react-router-dom';
import logo from './logo.svg';
import './App.scss';
// Screens
import Home from './screens/home';

function App():JSX.Element {
	return (
		<Router>
			<Switch>
				{/* General Flow */}
				<Route path="/" exact={true} component={Home} />
				<Route path="/home" exact={true} component={Home} />
			</Switch>
		</Router>
	);
}

export default App;
