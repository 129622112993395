/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import logo from '../assets/images/logo-shockoe.png';
import gpayMark from '../assets/images/GPayMark.png';
import apayMark from '../assets/images/APayMark.svg';
import styles from '../styles/screens/home.module.scss';
import GooglePayButton from '@shockoe.com/react-google-pay';
import ApplePayButton from '@shockoe.com/react-apple-pay';
import axios from 'axios';

function App():JSX.Element {
	const processGooglePayment = (paymentData: unknown) => {
		console.log('paymentData', paymentData);
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({ transactionState: 'SUCCESS' });
			}, 3000);
		});
	};

	const getApplePaySession = async (urlSession: string) => {
		const data = JSON.stringify({
			"appleUrl": urlSession
		});
		
		const config = {
			method: 'post',
			url: 'https://f9ne826pbe.execute-api.us-east-2.amazonaws.com/session',
			headers: {
				'Content-Type': 'application/json'
			},
			data : data
		};

		return await axios(config).then((response) => {
			return response.data.body.body;
		})
		.catch((error) => {
			console.log('error===>', error);
		});
	}

	const processApplePayment = (paymentData: any) => {
			return new Promise((resolve) => {
				console.log('paymentData===>', paymentData);
				setTimeout(() => {
					resolve(true);
				}, 3000);
			});
	};

	return (
		<div className={styles.body}>
			<img src={logo} className={styles.logo} alt="logo" />
			<p>Test page for the <span>NPM Packges of Payments</span></p>

			<p><span>Note: </span> Use test accounts to use payment methods, review the documentation located under each button</p>

			<div className={styles.row}>
				<div className={styles.column}>
					<GooglePayButton
						className={styles.payButton}
						environment="TEST"
						apiVersion={2}
						apiVersionMinor={0}
						allowedAuthMethods={['PAN_ONLY', 'CRYPTOGRAM_3DS']}
						allowedCardNetworks={['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA']}
						gateway="example"
						merchantId="12345678901234567890"
						merchantName="Merchant Name"
						transactionInfo={{
							displayItems: [
								{
									label: 'Deposit Amount',
									type: 'SUBTOTAL',
									price: '1.00',
								},
							],
							countryCode: 'US',
							currencyCode: 'USD',
							totalPriceStatus: 'FINAL',
							totalPrice: '1.00',
							totalPriceLabel: 'Total',
						}}
						processGooglePayment={processGooglePayment}>
						<img src={gpayMark} alt="GPay Shockoe Demo" />
					</GooglePayButton>

					<a href="https://badge.fury.io/js/@shockoe.com%2Freact-google-pay">
						<img
							src="https://badge.fury.io/js/@shockoe.com%2Freact-google-pay.svg"
							alt="npm version"
							height="18"
						/>
					</a>
					<br />
					<a className={styles.link} href="https://developers.google.com/pay/api/web/guides/resources/test-card-suite">Test card suite</a>
				</div>

				<div className={styles.column}>
					<ApplePayButton
						className={styles.payButton}
						merchantIdentifier="merchant.shockoe.pay"
						transactionInfo={{
							"countryCode": "US",
							"currencyCode": "USD",
							"merchantCapabilities": [
									"supports3DS",
									"supportsDebit",
									"supportsCredit"
							],
							"supportedNetworks": [
									"visa",
									"masterCard",
									"amex",
									"discover"
							],
							"supportsCouponCode": true,
							"couponCode": "20PERCENTOFF",
							"shippingMethods": [
									{
											"label": "Shipping",
											"amount": "1.00",
											"detail": "Standard",
											"identifier": "shipping"
									}
							],
							"lineItems": [
									{
											"label": "Item",
											"amount": "15.00"
									},
									{
											"label": "20% Off",
											"amount": "-3.00"
									},
									{
											"label": "Shipping",
											"amount": "1.00"
									}
							],
							"total": {
									"label": "Coupon Code Demo (Card is not charged)",
									"amount": "13.00"
							}
						}}
						getAppleSession={getApplePaySession}
						processApplePayment={processApplePayment}>
						<img src={apayMark} alt="APay Shockoe Demo" />
					</ApplePayButton>

					<a href="https://badge.fury.io/js/@shockoe.com%2Freact-apple-pay">
						<img
							src="https://badge.fury.io/js/@shockoe.com%2Freact-apple-pay.svg"
							alt="npm version"
							height="18" 
						/>
					</a>
					<br />
					<a className={styles.link} href="https://developer.apple.com/apple-pay/sandbox-testing/">Sandbox Testing</a>
				</div>
			</div>
		</div>
	);
}

export default App;
